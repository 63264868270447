<template>
  <v-container class="min-height-100">
    <v-row class="min-height-100 auth-page register-page ma-0">
      <!-- Left section that's hidden on phones -->
      <v-col class="d-none d-md-flex" md="6">
        <div
          class="logo-container d-flex align-center px-12 cursor-pointer"
          :class="host.theme.type === 'dark' ? 'black' : 'white'"
          @click="$router.push({ name: 'Home' })"
        >
          <img :src="host.images.logo" />
        </div>
      </v-col>

      <!-- Right section that does have a form -->
      <v-col class="min-height-100" cols="12" md="6">
        <div class="text-right text-subtitle-2">
          Already have an account? &nbsp;
          <router-link class="text-decoration-none" :to="{ name: 'Login' }">
            Login
          </router-link>
        </div>

        <div class="form-container d-flex flex-column justify-center min-height-100">
          <div class="text-h5 font-weight-bold">
            Reset Password
          </div>
          <div class="text-subtitle-2 mt-3 mb-8 grey--text text--darken-2">
            Please go ahead and enter your new password.
          </div>

          <v-form @submit.prevent="handleSubmit">
            <v-text-field
              v-model.trim="form.email"
              type="email"
              label="Email"
              outlined
              required
              readonly
            ></v-text-field>

            <v-text-field
              v-model.trim="form.password"
              type="password"
              :error-messages="passwordErrors"
              label="Password"
              outlined
              required
              @input="$v.form.password.$touch()"
              @blur="$v.form.password.$touch()"
            ></v-text-field>

            <v-text-field
              v-model.trim="form.password_confirmation"
              type="password"
              :error-messages="confirmPasswordErrors"
              label="Confirm Password"
              outlined
              required
              @input="$v.form.password_confirmation.$touch()"
              @blur="$v.form.password_confirmation.$touch()"
            ></v-text-field>

            <div class="d-flex justify-space-between align-center">
              <span></span>

              <v-btn
                class="shadow--primary"
                color="primary"
                type="submit"
                large
                depressed
                :loading="isLoading"
                :disabled="isLoading"
              >
                Reset Password
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, minLength, email, sameAs } from "vuelidate/lib/validators"

const originalForm = () => ({
  email: "",
  password: "",
  password_confirmation: ""
})

export default {
  name: "Register",

  data: () => ({
    isLoading: false,
    form: originalForm()
  }),

  computed: {
    passwordErrors () {
      // initiate the array of error messages
      const errors = []

      // if there has been no activity, show no error
      if (!this.$v.form.password.$dirty) return errors

      // else test it
      !this.$v.form.password.required && errors.push('Password is required')
      !this.$v.form.password.minLength && errors.push('Password must be 8 characters long')

      // return the error messages
      return errors
    },

    confirmPasswordErrors () {
      // initiate the array of error messages
      const errors = []

      // if there has been no activity, show no error
      if (!this.$v.form.password_confirmation.$dirty) return errors

      // else test it
      !this.$v.form.password_confirmation.required && errors.push('Please re-type your password')
      !this.$v.form.password_confirmation.sameAs && errors.push('Passwords do not match')

      // return the error messages
      return errors
    },
  },

  // Define the vuelidate validation rules
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      },
      password_confirmation: {
        required,
        sameAs: sameAs('password')
      }
    }
  },

  methods: {
    async handleSubmit() {
      // disable the button
      this.isLoading = true

      // Run validations
      await this.$v.$touch()

      // If any validation error, stop the execution
      if (this.$v.$anyError) {
        this.isLoading = false

        return false
      }

      // Show a loader while the request is being processed
      const loaderId = Symbol()
      await this.$store.dispatch("loaders/add", loaderId)

      try {
        await axios({
          url: "/api/password/reset",
          method: "POST",
          data: {
            token: this.$route.query.token,
            ...this.form
          }
        })

        // Show alert
        this.$store.dispatch("toasts/add", { text: "Password has been reset. Login now." })

        // Redirect to Home
        this.$router.replace({ name: "Login" })
      } catch (error) {
        logger({ type: "Reset Error", error })

        // show the error message to the user
        this.$store.dispatch("toasts/add", { text: "An Error Occurred." })
      } finally {
        // remove the loader
        await this.$store.dispatch("loaders/remove", loaderId)

        // enable the button
        this.isLoading = false
      }
    }
  },

  /**
   * As soon as the component is ready
   *
   * @returns {void}
   */
  created() {
    const emailAddress = this.$route.query.email || null
    const resetToken = this.$route.query.token || null

    // If they both exist
    if (emailAddress && email(emailAddress) && resetToken) {
      // Set the email value
      this.form.email = emailAddress
    }
    // Otherwise
    else {
      // Show alert
      this.$store.dispatch("toasts/add", { text: "Invalid Link." })

      // Redirect to Login
      this.$router.replace({ name: "Login" })
    }
  }
}
</script>

<style lang="stylus" src="@/stylus/auth.styl"></style>
